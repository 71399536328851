import React from "react";
import { Section } from "../../components/Core";
import { Container } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import AirtableForm from "../../components/AirtableForm";

import { useQueryParam, StringParam } from "use-query-params";

const EditSubmissionPage = () => {
    // something like: ?id=abc123 in the URL
    const [id] = useQueryParam("id", StringParam);

    return (
        <>
            <PageWrapper>
                <Section>
                    <Container
                        fluid
                        css={`
                          padding-left: 0;
                          padding-right: 0;
                        `}
                    >
                        <AirtableForm
                            formId="shrx7FdB2RzOEtPv8"
                            prefill={
                                {
                                    'App Id': id
                                }
                            }
                        />
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default EditSubmissionPage;
